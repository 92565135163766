import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ExclusionItem, GetPharmacyResponse, Pharmacy } from 'src/app/models/QualificationFilter'
import { UserState } from "../store/user.reducer";
import { BaseMhService } from "./basemh.service";
import { UserSession } from '../models/user';
import { BINExclusionResponse, FileUploadResponse, Status } from "../models/submit-response";
import { throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class QualificationFilterService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }

  public getPharmaciesByUser(actionID: number, corpID: number) {
    const session: UserSession = this.getStoreUserSession();
    let corp = actionID == 2 ? corpID : session.corpID;

    return this.http
      .get(environment.apiBaseUrl + "bulkbinexclusion/v1/BulkBINExclusionAPI/GetPharmaciesByUser?userId=" + session.uid + "&corpId=" + corp + "&actionId=" + actionID, { headers: this.getHeaders() })
      .pipe(
        map((response: GetPharmacyResponse) => response),
        catchError(this.errorHandler)
      );
  }

  public addBINExclusions(payload: {"items": ExclusionItem[]}) {
    return this.http
      .post(environment.apiBaseUrl + "bulkbinexclusion/v1/BulkBINExclusionAPI/AddBINExclusions", payload, { headers: this.getHeaders() })
      .pipe(
        map((response: BINExclusionResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error adding Bin Exclusions" }))
      );
  }

  public fileUploadBinExclusions(postedFile: FormData) {
    const session: UserSession = this.getStoreUserSession();
    //Take content type out of header
    var formHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Ocp-Apim-Subscription-Key': environment.subcriptionKey,
      Authorization: 'Bearer ' + this.getUserToken(),
    });

    return this.http
      .post(environment.apiBaseUrl + "bulkbinexclusion/v1/BulkBINExclusionAPI/FileUpload?userId=" + session.uid, postedFile, { headers: formHeaders })
      .pipe(
        map((response: FileUploadResponse) => response),
        catchError(error => 
            throwError("Error while uploading Bulk Bin Exclusions file: " + error.error.ErrorMessage)
        )

      );
  }

  public fileUploadNDCExclusions(postedFile: FormData, exclusionType: number, clientID: number, uploadType: number) {
    const session: UserSession = this.getStoreUserSession();
    //Take content type out of header
    var formHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Ocp-Apim-Subscription-Key': environment.subcriptionKey,
      Authorization: 'Bearer ' + this.getUserToken(),
    });

    return this.http
      .post(environment.apiBaseUrl + "bulkbinexclusion/v1/BulkBINExclusionAPI/FileUploadNDCExclusion?userId=" + session.uid + "&exclusionType=" + exclusionType + "&clientID=" + clientID + "&uploadType=" + uploadType, postedFile, { headers: formHeaders })
      .pipe(
        map((response: Status) => response),
        catchError(error => 
            throwError("Error while uploading file: " + error.error.ErrorMessage)
        )

      );
  }

}