import { QualificationFilterTypes } from './qualification-filter.actions';
import { GetPharmacyResponse, Status } from 'src/app/models/QualificationFilter'

export interface QualificationFilterState {
  getPharmacyResponse: GetPharmacyResponse,
  NDCUploadResponse: Status
}

export const initialState = {
  getPharmacyResponse: null,
  NDCUploadResponse: null
};

export function qualificationFilterStateReducer(state = initialState, action: any): QualificationFilterState {
  switch (action.type) {
    case QualificationFilterTypes.PHARMACY_GET_RES: {
      return { ...state, getPharmacyResponse: action.getPharmacyResponse };
    }
    case QualificationFilterTypes.FILEUPLOAD_NDCEXCLUSIONS_RES: {
      return { ...state, NDCUploadResponse: action.response };
    }
    default: {
      return state;
    }
  }
  
}