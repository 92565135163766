<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content *ngIf="data.message != ''" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div [innerHtml]="data.message">
  </div>
  <div>
    <span *ngIf=(!this.flag1) class="message-text">{{data.msg1}} <br /></span>
    <span *ngIf=(!this.flag2) class="message-text">{{data.msg2}} <br /></span>
    <span *ngIf=(!this.flag3) class="message-text">{{data.msg3}} <br /></span>
  </div>
  <div class="bottomBar">

    <span *ngIf="data.btnok"><button mat-flat-button type="button" class="group-btn"
        [mat-dialog-close]="true">{{data.btntext}}</button></span>
    <span *ngIf="data.showCancelBtn">
      &nbsp;&nbsp;
      <button mat-flat-button type="button" class="group-btn" [mat-dialog-close]="false">{{data.btnCancelText}}</button>
    </span>
  </div>
</div>