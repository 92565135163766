import { Injectable } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { QualificationFilterService } from '../service/qualification-filter.service';
import { GetPharmaciesByUser, GetPharmaciesByUserResults, GetPharmaciesByUserError, AddBINExclusions, AddBINExclusionsResults, AddBINExclusionsError, FileUploadBinExclusions, FileUploadBinExclusionsError, FileUploadBinExclusionsResults, FileUploadNDCExclusions, FileUploadNDCExclusionsResults, FileUploadNDCExclusionsError} from './qualification-filter.actions';

@Injectable()
export class QualificationFilterEffects {
    constructor(private qualificationFilterService: QualificationFilterService, private actions$: Actions) {}
    
      getPharmaciesByUser$ = createEffect(() => this.actions$.pipe(
        ofType(GetPharmaciesByUser),
        switchMap(action =>
          this.qualificationFilterService.getPharmaciesByUser(action.actionID, action.corpID)
            .pipe(
              map(
                data => {
                  return GetPharmaciesByUserResults({ pharmacyResponse: data })
                }
              ),
              catchError(
                error => observableOf(GetPharmaciesByUserError({ errorMessage: error }))
              )
            )
        )
      ))

      AddBINExclusions$ = createEffect(() => this.actions$.pipe(
        ofType(AddBINExclusions),
        switchMap(action =>
          this.qualificationFilterService.addBINExclusions(action.data)
            .pipe(
              map(
                data => {
                  return AddBINExclusionsResults({ response: data })
                }
              ),
              catchError(
                error => observableOf(AddBINExclusionsError({ response: error }))
              )
            )
        )
      ))

      FileUploadBinExclusions$ = createEffect(() => this.actions$.pipe(
        ofType(FileUploadBinExclusions),
        switchMap(action =>
          this.qualificationFilterService.fileUploadBinExclusions(action.postedFile)
            .pipe(
              map(
                data => {
                  return FileUploadBinExclusionsResults({ response: data })
                }
              ),
              catchError(
                error => observableOf(FileUploadBinExclusionsError({ response: error }))
              )
            )
        )
      ))

      FileUploadNDCExclusions$ = createEffect(() => this.actions$.pipe(
        ofType(FileUploadNDCExclusions),
        switchMap(action =>
          this.qualificationFilterService.fileUploadNDCExclusions(action.postedFile, action.exclusionType, action.clientID, action.uploadType)
            .pipe(
              map(
                res => {
                  return FileUploadNDCExclusionsResults({ response: res })
                }
              ),
              catchError(
                error => observableOf(FileUploadNDCExclusionsError({ errorMessage: error }))
              )
            )
        )
      ));
}